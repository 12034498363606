@charset "UTF-8";
/* ============ COLORS ============= */
/* ============ NUMBERS ============ */
/* ============ SIZES ============ */
/* ============ KEEP GRID WIDTHS ============ */
/* ============ DISPLAY ============ */
/* ============ SCREEN SIZES ============ */
/* ============ FONTS ============ */
/* ============ POSITIONING ============ */
/* ============ HEIGHTS ============ */
/* ============ COLORS ============= */
/* ============ NUMBERS ============ */
/* ============ SIZES ============ */
/* ============ KEEP GRID WIDTHS ============ */
/* ============ DISPLAY ============ */
/* ============ SCREEN SIZES ============ */
/* ============ FONTS ============ */
/* ============ POSITIONING ============ */
/* ============ HEIGHTS ============ */
/* ============ COLORS ============= */
/* ============ NUMBERS ============ */
/* ============ SIZES ============ */
/* ============ KEEP GRID WIDTHS ============ */
/* ============ DISPLAY ============ */
/* ============ SCREEN SIZES ============ */
/* ============ FONTS ============ */
/* ============ POSITIONING ============ */
/* ============ HEIGHTS ============ */
.nav-container #nav.opt-fx-fade-inout.opt-sb2.xm-mega-menu-categories.xm-mega-menu.nav-regular li.level0 > a {
  font-family: "Open Sans", sans-serif;
  text-transform: none;
  color: #212934;
  font-weight: normal;
}
.nav-container #nav.opt-fx-fade-inout.opt-sb2.xm-mega-menu-categories.xm-mega-menu.nav-regular li.level0.parent > a .caret {
  border-top-color: #1d242d;
}
.nav-container #nav.opt-fx-fade-inout.opt-sb2.xm-mega-menu-categories.xm-mega-menu.nav-regular li.level0.parent:hover > a .caret {
  border-top-color: #2c348c;
}
.nav-container #nav.opt-fx-fade-inout.opt-sb2.xm-mega-menu-categories.xm-mega-menu.nav-regular li.level0:hover > a {
  color: #2c348c !important;
}
.nav-container #nav.opt-fx-fade-inout.opt-sb2.xm-mega-menu-categories.xm-mega-menu.nav-regular li.level0:hover > a span {
  color: #2c348c;
}
.nav-container #nav.opt-fx-fade-inout.opt-sb2.xm-mega-menu-categories.xm-mega-menu.nav-regular li.level0 .nav-panel--dropdown {
  border-top: 3px solid #2c348c;
  padding: 0;
}
.nav-container #nav.opt-fx-fade-inout.opt-sb2.xm-mega-menu-categories.xm-mega-menu.nav-regular li.level0 .nav-panel--dropdown li > a {
  padding: 8px 20px;
}
.nav-container #nav.opt-fx-fade-inout.opt-sb2.xm-mega-menu-categories.xm-mega-menu.nav-regular li.level0 .nav-panel--dropdown li > a span {
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
}
.nav-container #nav.opt-fx-fade-inout.opt-sb2.xm-mega-menu-categories.xm-mega-menu.nav-regular li.level0 .nav-panel--dropdown li > a:hover {
  background-color: rgba(226, 226, 226, 0.75);
}
.nav-container #nav.opt-fx-fade-inout.opt-sb2.xm-mega-menu-categories.xm-mega-menu.nav-regular li.level0 .nav-panel--dropdown li > a:hover span {
  color: #1d242d;
}
.nav-container #nav.opt-fx-fade-inout.opt-sb2.xm-mega-menu-categories.xm-mega-menu.nav-regular li.nav-item.nav-item--home.level0 {
  display: none;
}
.nav-container #nav.opt-fx-fade-inout.opt-sb2.xm-mega-menu-categories.xm-mega-menu.nav-regular .nav-submenu--mega > li > a {
  font-family: "Open Sans", sans-serif;
}

#root-wrapper header#header {
  position: fixed;
  z-index: 9999;
  width: 100%;
  background-color: #fff;
}
#root-wrapper header#header .xm-grid-header > .container {
  width: 100%;
  margin: 0;
  padding: 0;
}
#root-wrapper header#header .xm-grid-header > .container .desktop-top-header-container {
  background-color: #2c348c;
}
#root-wrapper header#header .xm-grid-header > .container .desktop-top-header-container .header .userCode, #root-wrapper header#header .xm-grid-header > .container .desktop-top-header-container .header .userName, #root-wrapper header#header .xm-grid-header > .container .desktop-top-header-container .header .userSwitch, #root-wrapper header#header .xm-grid-header > .container .desktop-top-header-container .header .userSwitch a, #root-wrapper header#header .xm-grid-header > .container .desktop-top-header-container .header .welcome-msg {
  color: #fff !important;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 2.4em;
}
#root-wrapper header#header .xm-grid-header > .container .desktop-top-header-container .header .links > li > a {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: bold;
}
#root-wrapper header#header .xm-grid-header > .container .desktop-top-header-container .header #mini-cart.dropdown {
  line-height: 2.4em;
}
#root-wrapper header#header .xm-grid-header > .container .desktop-top-header-container .header #mini-cart.dropdown .feature-icon-hover a {
  color: #fff;
  font-family: "Open Sans", sans-serif;
}
#root-wrapper header#header .xm-grid-header > .container .desktop-top-header-container .header #mini-cart.dropdown .feature-icon-hover a .hide-below-960 {
  font-size: 14px;
}
#root-wrapper header#header .xm-grid-header > .container .desktop-top-header-container .header #mini-cart.dropdown span.fa {
  font-size: 20px;
}
#root-wrapper header#header .xm-grid-header > .container .desktop-top-header-container .header #mini-cart.dropdown.open .feature-icon-hover a {
  color: #2c348c;
}
#root-wrapper header#header .xm-grid-header > .container .desktop-top-header-container .header #mini-cart.dropdown.open .feature-icon-hover a:hover span.fa::before {
  color: #2c348c !important;
}
#root-wrapper header#header .xm-grid-header > .container .desktop-top-header-container .header #mini-cart.dropdown .feature-icon-hover a:hover span.fa::before {
  color: #2c348c !important;
}
#root-wrapper header#header .xm-grid-header > .container .desktop-main-header-container.container {
  display: block;
  float: none;
}
#root-wrapper header#header .xm-grid-header > .container .desktop-main-header-container.container .search-wrapper .form-search .input-text {
  color: #9ea0a4 !important;
}
#root-wrapper header#header .xm-grid-header > .container .mobile-header-container .header .logo {
  max-width: 105px;
  margin: 0;
  width: 100%;
}
#root-wrapper header#header .xm-grid-header > .container .mobile-header-container .grid12-0.item-left {
  float: left;
}
#root-wrapper header#header .xm-grid-header > .container .mobile-header-container .grid12-0.item-right {
  float: right;
}
#root-wrapper header#header .xm-grid-header > .container .mobile-header-container .mh-category-btn .fa.fa-bars.userCode {
  color: #1d242d;
  margin: 0 !important;
}
#root-wrapper header#header .xm-grid-header > .container .mobile-header-container .form-search .button {
  color: #1d242d;
}

/* ============ COLORS ============= */
/* ============ NUMBERS ============ */
/* ============ SIZES ============ */
/* ============ KEEP GRID WIDTHS ============ */
/* ============ DISPLAY ============ */
/* ============ SCREEN SIZES ============ */
/* ============ FONTS ============ */
/* ============ POSITIONING ============ */
/* ============ HEIGHTS ============ */
/* ============ LIST VIEW ============ */
.category-products-listing .item .add-cart-wrapper button.button {
  width: 100%;
  max-width: none;
}
.category-products-listing .item .add-cart-wrapper button.button span {
  width: 100%;
}
.category-products-listing .item .add-cart-wrapper button.button span span {
  padding: 0;
  width: 100%;
}
.category-products-listing .item .add-cart-wrapper button.button.add-cart-button, .category-products-listing .item .add-cart-wrapper button.button.btn-cart {
  margin-right: 0;
  margin-bottom: 15px;
}

/* ============ COLORS ============= */
/* ============ NUMBERS ============ */
/* ============ SIZES ============ */
/* ============ KEEP GRID WIDTHS ============ */
/* ============ DISPLAY ============ */
/* ============ SCREEN SIZES ============ */
/* ============ FONTS ============ */
/* ============ POSITIONING ============ */
/* ============ HEIGHTS ============ */
.xm-grid-product .product-shop.module-product-details .availability.stockStatusCon {
  float: none;
  text-align: left;
}
.xm-grid-product .product-shop.module-product-details .add-cart-wrapper button.btn-cart.add-cart-button span {
  background-color: #2c348c;
}
.xm-grid-product .product-shop.module-product-details .add-cart-wrapper button.btn-cart.add-cart-button:hover span {
  background-color: #fff;
  color: #2c348c;
}
.xm-grid-product .gen-tabs .tabs a {
  background-color: #2c348c;
  color: #fff;
}
.xm-grid-product .gen-tabs .tabs a.current {
  background-color: #fff;
  color: #2c348c;
}
.xm-grid-product .gen-tabs .acctab {
  background-color: #2c348c;
  color: #fff;
}
.xm-grid-product .gen-tabs .acctab.current, .xm-grid-product .gen-tabs .acctab:focus, .xm-grid-product .gen-tabs .acctab:hover {
  background-color: #fff;
  color: #2c348c;
}

/* ============ COLORS ============= */
/* ============ NUMBERS ============ */
/* ============ SIZES ============ */
/* ============ KEEP GRID WIDTHS ============ */
/* ============ DISPLAY ============ */
/* ============ SCREEN SIZES ============ */
/* ============ FONTS ============ */
/* ============ POSITIONING ============ */
/* ============ HEIGHTS ============ */
footer#footer {
  width: 102%;
  margin-left: -1%;
  clear: left;
}
footer#footer .main-footer-container {
  width: 102%;
  margin-left: -1%;
  padding-top: 50px;
}
footer#footer h6.block-title.heading {
  font-size: 16px;
  margin-bottom: 16px;
}
footer#footer h6.block-title.heading + .block-content ul.bullet li a {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
}
footer#footer p {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
}
footer#footer p span {
  font-size: 16px !important;
}

body .keep-width.grid12-1 {
  width: 6.33% !important;
}
body .keep-width.grid12-2 {
  width: 14.66% !important;
}
body .keep-width.grid12-3 {
  width: 23% !important;
}
body .keep-width.grid12-4 {
  width: 31.33% !important;
}
body .keep-width.grid12-5 {
  width: 39.67% !important;
}
body .keep-width.grid12-6 {
  width: 48% !important;
}
body .keep-width.grid12-7 {
  width: 56.33% !important;
}
body .keep-width.grid12-8 {
  width: 64.67% !important;
}
body .keep-width.grid12-9 {
  width: 73% !important;
}
body .keep-width.grid12-10 {
  width: 81.33% !important;
}
body .keep-width.grid12-11 {
  width: 89.67% !important;
}
body button, body input, body select, body textarea {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: #9ea0a4;
}
body button.button span {
  border-radius: 0;
  background-color: #2c348c;
  color: #fff;
  font-family: "Open Sans", sans-serif;
  size: 14px;
  font-weight: 600;
}
body button.button:hover span {
  background-color: #fff;
  color: #2c348c;
}
body #scroll-to-top {
  background-image: none;
  background-color: #2c348c;
}
body #scroll-to-top::before {
  font-family: "fontAwesome";
  color: #fff;
  content: "";
  font-size: 30px;
  left: 12px;
  position: absolute;
  top: 15px;
  text-indent: 0;
}
body #scroll-to-top:hover {
  background-color: #fff;
}
body #scroll-to-top:hover::before {
  color: #2c348c;
}

@media (min-width: 960px) {
  .show-below-960 {
    display: none;
  }
}
@media only screen and (min-width: 960px) and (max-width: 1303px) {
  .main.container {
    padding-top: 184px;
  }
}
@media only screen and (min-width: 1304px) {
  .main.container {
    padding-top: 114px;
  }
}
@media (max-width: 959px) {
  .hide-below-960 {
    display: none;
  }

  .main.container {
    padding-top: 46px;
  }
}

/*# sourceMappingURL=general.css.map */
